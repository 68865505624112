<template>
  <div class="news">
    <!--    bg-gradient-to-r from-orange-200 to-indigo-200-->
    <LSection>
      <template #body>
        <div class="flex">
          <div class="heading1" :class="{ 'w-full': !user?.__isAppAdmin }">
            News from the Quantum Ecosystem
            <hr class="gradientLine" />
          </div>
          <button
            v-if="user?.__isAppAdmin"
            @click="$router.push('/createnewsitem')"
            class="button main h-fit ml-auto mb-10"
          >
            Create A News Item
          </button>
        </div>

        <p class="pb-8 text-justify">
          Welcome to EQRC news section, your prime source for the latest
          developments, breakthroughs, and events in the world of Quantum
          Technologies. Here, we bring together the most significant news to
          keep you informed about the advancements shaping the future of quantum
          computing, research, and industry. From policy updates to
          international conferences and groundbreaking research findings, stay
          connected with the pulse of the quantum ecosystem.
        </p>

        <div
          class="flex k1:flex-row flex-col k1:items-start items-center k1:gap-0 gap-8 mb-12"
        >
          <div class="flex-grow">
            <CardsNews></CardsNews>
          </div>
          <div class="flex justify-center w-fit">
            <div class="max-w-sm w-[320px] h-fit space-y-4">
              <QuantumReadyTwitterStream></QuantumReadyTwitterStream>
              <div
                class="flex items-center shadow justify-center gap-2 p-4 bg-white w-full border rounded-xl border-gray-300"
              >
                <p>Follow EQRC on LinkedIn</p>
                <Linkdin />
              </div>
            </div>
          </div>
        </div>
      </template>
    </LSection>
  </div>
</template>

<script>
import { useDocuments } from "@/composables/useDocuments";
import CardsNews from "@/components/News/CardsNews";
import { useUserPermissions } from "@/composables/useUserPermissions";
import QuantumReadyTwitterStream from "@/components/News/QuantumReadyTwitterStream.vue";
import LSection from "@/components/layout/LSection.vue";
import Linkdin from "@/components/layout/Linkdin.vue";

export default {
  name: "News",
  components: {
    Linkdin,
    LSection,
    QuantumReadyTwitterStream,
    CardsNews,
  },
  setup() {
    const { user } = useUserPermissions();
    const { loadDocuments } = useDocuments("News");

    loadDocuments(true);

    return {
      user,
    };
  },
};
</script>

<style scoped></style>
