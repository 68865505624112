<script>
import { onMounted, ref } from "vue";

export default {
  name: "QuantumReadyTwitterStream",
  props: {
    profileName: {
      type: String,
      default: "euquantumready",
    },
  },
  setup(props) {
    const twitterFeed = ref(null);

    onMounted(() => {
      loadTwitterStream();
    });

    function loadTwitterStream() {
      // Check if the Twitter widgets script is already loaded
      if (!window.twttr) {
        // Load the Twitter Widgets JS script
        const script = document.createElement("script");
        script.src = "https://platform.twitter.com/widgets.js";
        script.async = true;
        script.onload = () => renderTwitterFeed();
        document.body.appendChild(script);
      } else {
        // If the script is already there, just render the feed
        renderTwitterFeed();
      }
    }

    function renderTwitterFeed() {
      // Use the Twitter Widgets JS to render the Twitter feed
      window.twttr.widgets
        .createTimeline(
          {
            sourceType: "profile",
            screenName: props.profileName,
          },
          twitterFeed.value,
          {
            theme: "light",
          }
        )
        .then(function () {
          console.log("Twitter feed has been rendered.");
        });
    }
    return {
      twitterFeed,
    };
  },
};
</script>

<template>
  <div class="QuantumReadyTwitterStream">
    <div id="twitter-feed" ref="twitterFeed" class="shadow rounded-xl">
      <!-- Twitter feed will be loaded here -->
    </div>
  </div>
</template>

<style scoped></style>
